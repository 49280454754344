<template>
  <div id="home">
    <div class="gonggaobox">
      <div class="gonggaocnetentbox"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {
    // 切换页面时滚动条自动滚动到顶部
    window.scrollTo(0, 0);
  },
  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped>
#home {
  width: 100%;
  .gonggaobox {
    background: rgb(248, 251, 255);
    width: 100%;
    margin: auto;
    .gonggaocnetentbox {
      margin: auto;
      width: 1200px;
    }
  }
}
</style>
