<template>
  <div id="aboutours">
    <div style="margin: auto">
      <div class="aboutours_banner">
        <div>
          <div class="aboutours_banner_p1">万物上链 数字世界</div>
          <div class="aboutours_banner_p2">
            EVERYTHING IS LINKED TO THE DIGITAL WORLD
          </div>
          <div class="aboutours_banner_p3">
            链玩始终践行科技向善理念，规范应用区块链技术，真实、准确、完整披露数字藏品信息;确保数字藏品的价值支撑;线上线下虚实联动，赋能实体经济，开创数字藏品新纪元，实现万物上链，助力数字经济健康稳健发展。
          </div>
        </div>
        <div class="aboutours_imgbj"></div>
        <div class="aboutours_imgbj_erweima"></div>
      </div>

      <div class="aboutours_footer">
        <div class="aboutours_footer_flex">
          <div class="footer_flex_leftbox">
            <img
              style="width: 100%; height: 100%"
              src="../assets/newImg/download/lianxi.jpg"
              alt=""
            />
          </div>
          <div class="footer_flex_rightimg">
            <img
              style="width: 100%; height: 100%"
              src="../assets/newImg/download/dibupeitu.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {
    // 切换页面时滚动条自动滚动到顶部
    window.scrollTo(0, 0);
  },
  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped>
#aboutours {
  height: 100%;
  width: 100%;
  min-width: 1300px;
  background: #000;
  .aboutours_banner {
    margin: auto;
    position: relative;
    width: 100%;
    height: 763.6px;
    min-width: 1300px;
    overflow: hidden;
    // background-image: url("../assets/newImg/download/xiazaibj.png");
    background: #000;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;

    .aboutours_banner_p1 {
      margin-top: 172.6px;
      margin-left: 94.1px;
      font-weight: 600;
      color: #fff;
      font-size: 58.9px;
      font-family: "DIN-Black";
    }
    .aboutours_banner_p2 {
      margin-top: 23px;
      margin-left: 94.1px;
      font-weight: 600;
      color: rgb(3, 126, 219);
      font-size: 13.5px;
      font-family: "DIN-Black";
    }
    .aboutours_banner_p3 {
      width: 473.9px;
      margin-top: 50.1px;
      margin-left: 94.1px;
      margin-bottom: 128.6px;
      color: #fff;
      font-size: 13.5px;
      line-height: 27.1px;
      font-family: "DIN-Black";
    }
    .aboutours_imgbj {
      position: absolute;
      top: 0;
      right: 0;
      width: 947.8px;
      height: 763.6px;
      background-image: url("../assets/newImg/download/xiazaibj.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 100%;
    }
    .aboutours_imgbj_erweima {
      position: absolute;
      top: 204.4px;
      right: 339.8px;
      width: 163.8px;
      height: 163.8px;
      background-image: url("../assets/newImg/download/erweima.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 99;
    }
  }

  .aboutours_footer {
    width: 100%;

    .aboutours_footer_flex {
      display: flex;
      .footer_flex_leftbox {
        width: 47%;
      }
      .footer_flex_rightimg {
        width: calc(100% - 47%);
      }
    }
  }
}
</style>
