<template>
  <div id="home">
    <div class="nav">
      <div class="con">
        <img
          style="width: 145px; height: 40px"
          src="../assets/newImg/index/lianwanlogo.png"
          alt=""
        />
        <div
          style="display: flex; width: 700px; justify-content: space-between"
        >
          <p
            class="routers"
            :class="{ check: $route.matched[1].path == '/home' }"
            @click="$router.push('/home')"
          >
            首页
          </p>
          <p
            class="routers"
            :class="{ check: $route.matched[1].path == '/announcement' }"
            @click="$router.push('/announcement')"
          >
            公告
          </p>
          <p
            class="routers"
            :class="{ check: $route.matched[1].path == '/business' }"
            @click="$router.push('/business')"
          >
            IP
          </p>
          <p
            class="routers"
            :class="{ check: $route.matched[1].path == '/history' }"
            @click="$router.push('/history')"
          >
            历程
          </p>
          <p
            class="routers"
            :class="{ check: $route.matched[1].path == '/certificate' }"
            @click="$router.push('/certificate')"
          >
            证书
          </p>
          <p
            class="routers"
            :class="{ check: $route.matched[1].path == '/download' }"
            @click="$router.push('/download')"
          >
            下载APP
          </p>
        </div>
      </div>
    </div>
    <div class="cont">
      <router-view />
    </div>

    <div class="footerbox">
      <div class="footerbox_contbox">
        <div class="footer_contbox_t1">
          版权所有© 杭州链街科技有限公司&nbsp;&nbsp;|&nbsp;&nbsp;
          <a
            target="_blank"
            href="https://www.beian.gov.cn/portal/registerSystemInfo"
            style="color: #666"
          >
            <img
              style="width: 20px; height: 20px"
              src="../assets/img/gongan.png"
              alt=""
            />浙公网安备 33010502007114号
          </a>
          &nbsp;&nbsp;<a
            target="_blank"
            href="https://beian.miit.gov.cn/#/Integrated/index"
            style="color: #666"
          >
            浙ICP备 19017819号-1</a
          >
        </div>
        <div class="footer_contbox_t2">
          Copyright © 2018杭州链街科技有限公司.All Rights Reserved
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      serach: "",
    };
  },
};
</script>
<style lang="less" scoped>
#home {
  width: 100%;
  min-width: 1300px;
  .nav {
    background: #000;
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 0px;
    min-width: 1300px;
    margin: auto;
    margin-bottom: 72px;
    border-bottom: 1px solid rgba(199, 187, 237, 0.5);
    .con {
      margin: auto;
      width: 1200px;
      min-width: 1300px;
      height: 72px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "DIN-Black";
      img {
        width: 120px;
        margin-right: 10px;
      }
      .routers {
        font-family: "DIN-Black";
        font-size: 21px;
        color: #fff;
        margin-left: 30px;
      }
      .routers:hover {
        color: #ccc;
      }
      .check {
        color: #ea3bf4;
      }
    }
  }
  .footerbox {
    width: 100%;
    background: #fff;
    .footerbox_contbox {
      width: 1280px;
      height: 150px;
      margin: auto;
      color: #666;
      text-align: center;
      .footer_contbox_t1 {
        font-size: 17px;
        padding-top: 50px;
      }
      .footer_contbox_t2 {
        font-size: 12px;
        margin-top: 13px;
      }
    }
  }
}
</style>
<style>
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
  background: #78b4b4;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
  border-radius: 10px;
  background: #ededed;
}
</style>
