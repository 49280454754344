import axios from "axios";
// 创建axios实例
const service = axios.create({
  baseURL: process.env.NODE_ENV == "production" ? "/api" : "/api", //api的base_url // baseURL: 'http://114.55.168.222:3002',//api的base_url
  timeout: 20000000, // 请求超时时间
});
// request拦截器
service.interceptors.request.use(
  (config) => {
    config.headers = {
      "Content-Type": "application/json",
    };

    // data:
    // transformRequest: [function(data) {
    //   data = JSON.stringify(data)
    //   return data
    // }],

    // console.log('config',config)
    // config.headers.token = 'jjjjjj'
    // let token = window.sessionStorage.getItem("token");
    // if (token) {
    //   if (config.url.indexOf("?") >= 1) {
    //     config.url = config.url + "&token=" + token;
    //   } else {
    //     config.url = config.url + "?token=" + token;
    //   }
    // }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log("错误："); // for debug
    Promise.reject(error);
  }
);

// response拦截器
service.interceptors.response.use(
  (response) => {
    /**
     * code为非0是抛错 可结合自己业务进行修改
     * const headers = response.headers
     */
    // const headers = response.headers;
    // if (headers["content-type"] === "application/pdf;charset = utf - 8 ") {
    //   return response.data;
    // }
    const res = response.data;
    if (response.status !== 200) {
      if (res.code === 403) {
        console.log(403);
      }
      return Promise.reject("error");
    } else {
      if (response.status == 200 && res.result && res.result.code == "401") {
        console.log(123);
      } else {
        return response.data;
      }
    }
  },
  (error) => {
    console.log("err:" + error); // for debug

    return Promise.reject(error);
  }
);
export default service;
