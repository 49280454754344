<template>
  <div id="history">
    <!--  -->
    <div class="history_banner">
      <div>
        <div class="history_banner_p">发 展 历 程</div>
      </div>
    </div>
    <!--  -->
    <div class="contbox">
      <div class="contbox_bj">
        <img
          style="width: 100%; height: 100%"
          src="../assets/newImg/history/lianwanbj.png"
          alt=""
        />
        <div style="position: absolute; top: 0.16rem; left: 1rem">
          <span class="p1"> | 企业大事件 | </span><span class="p2">EVENTS</span>
        </div>
      </div>
      <div class="contbox_bj2box">
        <div class="contbox_bj2">
          <img
            style="width: 100%; height: 100%"
            src="../assets/newImg/history/lc_cont.jpg"
            alt=""
          />
          <!-- 1 -->
          <div class="contbox_bj2_din1">
            <p class="contbox_bj2_p2">
              <span class="contbox_bj2_p1">2021</span> 5月15日
            </p>
            <p class="contbox_bj2_p3">链玩项目立项</p>
          </div>
          <!-- 2 -->
          <div class="contbox_bj2_din2">
            <p class="contbox_bj2_p2">
              <span class="contbox_bj2_p1">2021</span> 9月18日
            </p>
            <p class="contbox_bj2_p3">举办“NFT繁荣之路&数字藏品<br />研讨会”</p>
          </div>
          <!-- 3 -->
          <div class="contbox_bj2_din3">
            <p class="contbox_bj2_p2">
              <span class="contbox_bj2_p1">2021</span> 10月30日
            </p>
            <p class="contbox_bj2_p3">链玩app1.0版（元宇宙矿机模式）发布</p>
          </div>
          <!-- 4 -->
          <div class="contbox_bj2_din4">
            <p class="contbox_bj2_p2">
              <span class="contbox_bj2_p1">2021</span> 11月12日
            </p>
            <p class="contbox_bj2_p3">创世数藏“蜜蜂系列”<br />正式发行</p>
          </div>
          <!-- 5 -->
          <div class="contbox_bj2_din5">
            <p class="contbox_bj2_p2">
              <span class="contbox_bj2_p1">2022</span> 4月13日
            </p>
            <p class="contbox_bj2_p3">
              链玩app1.5版本<br />(数字藏品电商模式)<br />发布并试运营
            </p>
          </div>
          <!-- 6 -->
          <div class="contbox_bj2_din6">
            <p class="contbox_bj2_p2">
              <span class="contbox_bj2_p1">2022</span> 5月17日
            </p>
            <p class="contbox_bj2_p3">
              用户突破10万，当日交易额5500万，交易2.7万笔
            </p>
          </div>
          <!-- 7 -->
          <div class="contbox_bj2_din7">
            <p class="contbox_bj2_p2">
              <span class="contbox_bj2_p1">2022</span> 6月18日
            </p>
            <p class="contbox_bj2_p3">古天乐代言营销事件破圈，用户突破60万</p>
          </div>
          <!-- 8 -->
          <div class="contbox_bj2_din8">
            <p class="contbox_bj2_p2">
              <span class="contbox_bj2_p1">2022</span> 7月10日
            </p>
            <p class="contbox_bj2_p3">
              联合多家行业机构举办“元宇宙背景下的数字<br />文化发展论坛”;在线用户达2万+
            </p>
          </div>
          <!-- 9 -->
          <div class="contbox_bj2_din9">
            <p class="contbox_bj2_p2">
              <span class="contbox_bj2_p1">2022</span> 7月21日
            </p>
            <p class="contbox_bj2_p3">
              平台荣膺“2022最具有投资合作价值数字藏品平台”、“2022区块链技术应用最佳创新奖
            </p>
          </div>
          <!-- 10 -->
          <div class="contbox_bj2_din10">
            <p class="contbox_bj2_p2">
              <span class="contbox_bj2_p1">2022</span> 10月16日
            </p>
            <p class="contbox_bj2_p3">
              “秋季攻势千万钜献”活动，平台用户突破百万，交易额累计突破10亿元，市场回馈奖励1260万元
            </p>
          </div>
          <!-- 11 -->
          <div class="contbox_bj2_din11">
            <p class="contbox_bj2_p2">
              <span class="contbox_bj2_p1">2022</span> 11月12日
            </p>
            <p class="contbox_bj2_p3">链玩首届私董会召开，2.0征程正式起航</p>
          </div>
        </div>
      </div>
      <div class="contbox_bj2box_footimgbox">
        <div class="contbox_bj2box_footimg">
          <img
            style="width: 100%; height: 100%"
            src="../assets/newImg/history/lc_fontfoot.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <!--  -->
    <div class="history_footer">
      <div class="history_footer_flex">
        <div class="footer_flex_leftbox">
          <img
            style="width: 100%; height: 100%"
            src="../assets/newImg/history/lc_footleft.jpg"
            alt=""
          />
        </div>
        <div class="footer_flex_rightimg">
          <img
            style="width: 100%; height: 100%"
            src="../assets/newImg/history/lc_footright.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {
    // 切换页面时滚动条自动滚动到顶部
    window.scrollTo(0, 0);
  },
  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped>
#history {
  height: 100%;
  width: 100%;
  min-width: 1300px;
  .history_banner {
    margin: auto;
    position: relative;
    width: 100%;
    height: 300px;
    min-width: 1300px;
    overflow: hidden;
    background-image: url("../assets/newImg/history/lc_banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 70px;

    .history_banner_p {
      color: #fff;
      font-weight: 600;
      font-size: 59px;
      font-family: "DIN-Black";
      text-align: center;
      line-height: 300px;
    }
  }

  .contbox {
    width: 100%;
    background: #000;
    padding-top: 0.65rem;
    position: relative;
    .contbox_bj {
      width: 5.38rem;
      height: 0.744rem;
      margin-left: 0.677rem;
      margin-bottom: 0.65rem;
      .p1 {
        font-size: 0.446rem;
        font-family: "CFZJ";
        text-align: center;
        color: #fff;
      }
      .p2 {
        font-size: 0.51rem;
        font-family: "DIN-Black";
        text-align: center;
        color: rgb(17, 192, 255);
      }
    }
    .contbox_bj2box {
      width: 100%;
      padding-bottom: 0.95rem;

      .contbox_bj2 {
        margin: auto;
        position: relative;
        .contbox_bj2_p2 {
          font-size: 0.24rem;
          color: rgb(17, 192, 255);
          .contbox_bj2_p1 {
            font-size: 0.32rem;
            color: rgb(17, 192, 255);
          }
        }
        .contbox_bj2_p3 {
          font-size: 0.24rem;
          color: #fff;
          margin-top: 0.07rem;
        }
        .contbox_bj2_din1 {
          position: absolute;
          top: 1.3rem;
          left: 5.5rem;
        }
        .contbox_bj2_din2 {
          position: absolute;
          top: 2.4rem;
          left: 3.5rem;
        }
        .contbox_bj2_din3 {
          position: absolute;
          top: 3.9rem;
          left: 1.8rem;
        }
        .contbox_bj2_din4 {
          position: absolute;
          top: 5.3rem;
          left: 1.4rem;
        }
        .contbox_bj2_din5 {
          position: absolute;
          top: 6.8rem;
          left: 1.9rem;
        }

        .contbox_bj2_din6 {
          position: absolute;
          top: 8.4rem;
          left: 4.9rem;
        }
        .contbox_bj2_din7 {
          position: absolute;
          top: 7.8rem;
          left: 10.7rem;
        }
        .contbox_bj2_din8 {
          position: absolute;
          top: 6rem;
          left: 13rem;
        }
        .contbox_bj2_din9 {
          position: absolute;
          top: 4rem;
          left: 14.8rem;
        }
        .contbox_bj2_din10 {
          position: absolute;
          top: 1.6rem;
          left: 15rem;
        }
        .contbox_bj2_din11 {
          position: absolute;
          top: 0.7rem;
          left: 10.6rem;
        }
      }
    }
    .contbox_bj2box_footimgbox {
      width: 100%;
      background: #000;
    }
    .contbox_bj2box_footimg {
      width: 100%;
    }
  }

  .history_footer {
    width: 100%;
    .history_footer_flex {
      display: flex;
      .footer_flex_leftbox {
        width: 47%;
      }
      .footer_flex_rightimg {
        width: calc(100% - 47%);
      }
    }
  }
}
</style>
