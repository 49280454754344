import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/index.vue";
import home from "../views/home.vue";
import download from "../views/download.vue";
import announcement from "../views/announcement.vue";
import detail from "../views/detail.vue";
import business from "../views/business.vue";
import certificate from "../views/certificate.vue";
import history from "../views/history.vue";
// import swiper from "../views/swiper.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/home",
    component: HomeView,
    children: [
      {
        path: "/home",
        name: "home",
        component: home,
      },

      {
        path: "/download",
        name: "download",
        component: download,
      },
      {
        path: "/announcement",
        name: "announcement",
        component: announcement,
      },
      {
        path: "/detail/:url",
        name: "detail",
        component: detail,
      },
      // 业务
      {
        path: "business",
        name: "business",
        component: business,
      },
      // 证书
      {
        path: "certificate",
        name: "certificate",
        component: certificate,
      },
      // 历程
      {
        path: "history",
        name: "history",
        component: history,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
