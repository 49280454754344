<template>
  <div id="home">
    <div class="gonggao_banner">
      <div>
        <div class="gonggao_banner_p">实 时 公 告</div>
      </div>
    </div>
    <div class="gonggao_news">
      <div class="gonggao_sear">
        <div class="gonggao_sear_box1">
          <div style="padding-top: 74px"></div>
          <div class="gonggao_sear_box1_input">
            <input v-model="ggValue" class="gonggao_searinput" type="text" />
            <img
              class="gonggao_guanbi"
              src="../assets/newImg/announcement/guanbi.png"
              alt=""
              @click="deletesear()"
            />
            <img
              class="gonggao_fangdajing"
              src="../assets/newImg/announcement/fangdajing.png"
              alt=""
              @click="clicksear()"
            />
          </div>
        </div>
        <div class="gonggao_sear_tab">
          <div v-for="(item, index) in noticeTypeArr" :key="index">
            <div
              class="gonggao_tabp"
              :class="{ check: noticeType == index }"
              @click="tabType(index)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="''"> -->
      <div id="gonggao_news_box_box">
        <!--  -->
        <a
          class="gonggao_news_box"
          v-for="(item, index) in gonggaoArr"
          :key="index"
          target="_blank"
          :href="item.url"
        >
          <div class="gonggao_news_box_img">
            <img
              v-if="item.pic.length > '50'"
              style="width: 100px; height: 100px; border-radius: 5px"
              :src="item.pic"
              alt=""
            />
            <img
              v-else
              style="width: 100px; height: 100px; border-radius: 5px"
              :src="
                'https://lianstreets-bucket.oss-accelerate.aliyuncs.com/' +
                item.pic
              "
              alt=""
            />
          </div>
          <div class="gonggao_news_box_pbox">
            <p class="gonggao_news_box_pbox_p1">{{ item.name }}</p>
            <p class="gonggao_news_box_pbox_p2">
              {{ item.createTime }}
            </p>
          </div>
          <div class="gonggao_news_box_link"></div>
        </a>
      </div>
      <!-- <div @click="ggishow()" class="gonggao_news_box_btn"></div> -->
    </div>
    <div class="gonggao_footer">
      <div class="gonggao_footer_flex">
        <div class="footer_flex_leftbox">
          <img
            style="width: 100%; height: 100%"
            src="../assets/newImg/announcement/gg_footleft.jpg"
            alt=""
          />
        </div>
        <div class="footer_flex_rightimg">
          <img
            style="width: 100%; height: 100%"
            src="../assets/newImg/announcement/gg_footright.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import yyapi from "../api/api.js";
export default {
  components: {},
  data() {
    return {
      ggValue: "",
      ggValueparams: "",
      pageNum: 1,
      pageSize: 10,
      total: 0,
      gonggaoArr: [],
      noticeTypeArr: [
        { name: "全部", value: "0" },
        { name: "发新公告", value: "1" },
        { name: "寄售公告", value: "2" },
        { name: "合成公告", value: "3" },
        { name: "赋能公告", value: "4" },
        { name: "运营公告", value: "5" },
        { name: "活动公告", value: "6" },
      ],
      ishow: false,
      noticeType: "0",
    };
  },
  created() {
    // this.scroll();
  },
  mounted() {
    this.create();
    // 切换页面时滚动条自动滚动到顶部
    window.scrollTo(0, 0);
    console.log(this, "this1");
    let _this = this;
    var boxElement = document.getElementById("gonggao_news_box_box");
    boxElement.addEventListener(
      "scroll",
      function () {
        // 获取滚动条滚动区域盒子的高度
        var boxScrollpositionHeight = boxElement.offsetHeight;
        console.log("div内容区域的实际高度", boxElement.scrollHeight);
        //此时滚动条所在的高度
        var scrollcurHeight = boxElement.scrollTop;
        console.log("滚动条距离顶部的实际高度=", boxElement.scrollTop);
        console.log("hah", boxElement.clientHeight);

        // 关系公式：element.scrollHeight - element.scrollTop === element.clientHeight
        // 解释：此公式可以用于判断是否滚动到底
        if (
          boxElement.clientHeight + boxElement.scrollTop + 5 >
          boxElement.scrollHeight
        ) {
          _this.aaa();
          console.log("滚动到底部了");
        }
      },
      false
    );
  },
  computed: {},
  methods: {
    aaa() {
      console.log(this, "this2");
      let params = {
        type: "app_bulletin",
        name: this.ggValueparams,
        noticeType: this.noticeType == "0" ? "" : this.noticeType,
        pageNum: this.pageNum + 1,
        pageSize: 10,
      };
      yyapi.announcement(params).then((res) => {
        console.log(res, "公告");
        this.gonggaoArr = this.gonggaoArr.concat(res.data.list);
        this.total = res.data.total;
        this.pageNum = params.pageNum;
      });
    },
    create() {
      this.noticeType == "0";
      let params = {
        type: "app_bulletin",
        pageNum: 1,
        pageSize: 10,
        noticeType: this.noticeType == "0" ? "" : this.noticeType,
      };
      yyapi.announcement(params).then((res) => {
        this.$nextTick(() => {
          this.gonggaoArr = res.data.list;
          this.total = res.data.total;
          console.log(this.gonggaoArr, "gonggaoArr11111111");
          console.log(this.gonggaoArr[0].pic.length, "gonggaoArr22222222");
        });
      });
      console.log(this.gonggaoArr, "gonggaoArr");
    },
    deletesear() {
      this.ggValue = "";
    },
    clicksear() {
      this.ggValueparams = this.ggValue;
      this.pageNum = 1;
      let params = {
        type: "app_bulletin",
        name: this.ggValueparams,
        noticeType: this.noticeType == "0" ? "" : this.noticeType,
        pageNum: this.pageNum,
        pageSize: 10,
      };
      yyapi.announcement(params).then((res) => {
        console.log(res, "公告");
        this.gonggaoArr = res.data.list;
        this.total = res.data.total;
      });
    },
    tabType(e) {
      this.noticeType = e;
      this.pageNum = 1;
      console.log(e, "筛选下标");
      let params = {
        type: "app_bulletin",
        name: this.ggValueparams,
        noticeType: this.noticeType == "0" ? "" : this.noticeType,
        pageNum: this.pageNum,
        pageSize: 10,
      };
      yyapi.announcement(params).then((res) => {
        console.log(res, "公告");
        this.gonggaoArr = res.data.list;
        this.total = res.data.total;
      });
    },
  },
};
</script>
<style lang="less" scoped>
#home {
  height: 100%;
  width: 100%;
  min-width: 1300px;
  .gonggao_banner {
    margin: auto;
    position: relative;
    width: 100%;
    height: 300px;
    min-width: 1300px;
    overflow: hidden;
    background-image: url("../assets/newImg/announcement/gg_banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    margin-top: 70px;
    .gonggao_banner_p {
      color: #fff;
      font-weight: 600;
      font-size: 58.9px;
      font-family: "DIN-Black";
      text-align: center;
      line-height: 300px;
    }
  }
  .gonggao_news {
    width: 100%;
    height: 100%;
    background: #000;
    padding-bottom: 101.5px;

    .gonggao_sear {
      width: 100%;
      height: 100%;
      background: #000;

      .gonggao_sear_box1 {
        .gonggao_sear_box1_input {
          width: 620px;
          height: 68px;
          border-radius: 50px;
          padding-left: 30px;
          background: #fff;
          margin: auto;
          margin-bottom: 42px;
          position: relative;
          .gonggao_searinput {
            width: 430px;
            height: 68px;
            position: absolute;
            border: none;
            font-size: 25px;
            outline: none;
            color: #000;
          }
          .gonggao_guanbi {
            position: absolute;
            right: 130px;
            width: 40px;
            height: 26px;
            top: 20px;
          }
          .gonggao_fangdajing {
            position: absolute;
            right: 2.8px;
            width: 100px;
            margin: 2.8px;
          }
        }
      }
      .gonggao_sear_tab {
        display: flex;
        font-size: 18px;
        width: 640px;
        font-weight: 600;
        margin: auto;
        color: #ccc;
        justify-content: space-between;
        margin-bottom: 50px;
      }
      .check {
        color: #ea3bf4;
      }
    }

    #gonggao_news_box_box {
      max-height: 800px;
      overflow-y: scroll;
      // .gonggao_news_box:nth-child(1) {
      //   padding-top: 50.1px;
      // }
      .gonggao_news_box {
        width: 860px;
        height: 148px;
        border-bottom: 1px solid #fff;
        margin: auto;
        display: flex;
        .gonggao_news_box_img {
          width: 200px;
          margin: 25px 0px 0 74.4px;
        }
        .gonggao_news_box_pbox {
          .gonggao_news_box_pbox_p1 {
            width: 372.3px;
            font-size: 16.7px;
            list-style: 33.1px;
            color: #fff;
            /* font-family: DIN-Black; */
            margin-top: 23.47px;
          }
          .gonggao_news_box_pbox_p2 {
            font-size: 14.2px;
            margin-top: 30.5px;
            color: #fff;
            // font-family: "DIN-Black";
          }
        }
        .gonggao_news_box_link {
          margin: 50px 0 0 54.1px;
          width: 47.4px;
          height: 47.4px;
          background-image: url("../assets/newImg/announcement/gg_linkrouter.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }
    .gonggao_news_box_box2 {
      max-height: 800px;
      overflow-y: scroll;
      // .gonggao_news_box:nth-child(1) {
      //   padding-top: 50.1px;
      // }
      .gonggao_news_box {
        width: 860px;
        height: 148px;
        border-bottom: 1px solid #fff;
        margin: auto;
        display: flex;
        .gonggao_news_box_img {
          width: 200px;
          // height: 128.6px;
          // background-image: url("../assets/newImg/announcement/gg_img1.jpg");
          background-size: cover;
          background-repeat: no-repeat;
          margin: 25px 0px 0 74.4px;
        }
        .gonggao_news_box_pbox {
          .gonggao_news_box_pbox_p1 {
            width: 372.3px;
            font-size: 16.7px;
            list-style: 33.1px;
            color: #fff;
            /* font-family: DIN-Black; */
            margin-top: 23.47px;
          }
          .gonggao_news_box_pbox_p2 {
            font-size: 14.2px;
            margin-top: 30.5px;
            color: #fff;
            // font-family: "DIN-Black";
          }
        }
        .gonggao_news_box_link {
          margin: 50px 0 0 54.1px;
          width: 47.4px;
          height: 47.4px;
          background-image: url("../assets/newImg/announcement/gg_linkrouter.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }

    .gonggao_news_box_btn {
      margin: 67.7px auto 0px;
      width: 100%;
      text-align: center;
    }
  }
  .gonggao_footer {
    width: 100%;
    .gonggao_footer_flex {
      display: flex;
      .footer_flex_leftbox {
        width: 47%;
      }
      .footer_flex_rightimg {
        width: calc(100% - 47%);
      }
    }
  }
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active /deep/ {
  background-color: rgb(153, 25, 153);
  color: #fff;
}
</style>
