import request from "./axios";
export default {
  // 公告
  announcement(data) {
    return request({
      url: `/core/H5/cnavigate/public/list_front`,
      method: "post",
      data: data,
    });
  },
};
