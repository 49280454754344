<template>
  <div id="home">
    <div
      class="homeSwiper"
      style="
        width: 100%;
        position: relative;
        margin-top: 72px;
        margin-bottom: -37px;
      "
    >
      <video
        id="media"
        style="width: 100%"
        src="https://lianstreets-bucket.oss-accelerate.aliyuncs.com/1680243516000.m4v"
        autoplay
        loop
        preload="none"
        :muted="this.SYvedio"
        poster="https://lianstreets-bucket.oss-accelerate.aliyuncs.com/1680244731000.jpg"
      ></video>
      <div @click="clickSY()" class="ylcss"></div>
      <!-- controls -->
    </div>
    <div class="homeCont_two" style="margin-top: -3px">
      <img
        style="width: 100%; height: 100%"
        src="../assets/newImg/home/sy_box2_bj.jpg"
        alt=""
      />
      <div>
        <div class="homeCont_two_p1">用数字文化<br />链接世界</div>
        <div
          style="
            position: absolute;
            left: 1.5rem;
            top: 5.2rem;
            width: 1.1rem;
            height: 3px;
            background: rgb(234, 60, 245);
          "
        ></div>
        <div style="bottom: 2.54rem">
          <div class="homeCont_two_p2">DIGITAL CULTURE</div>
          <div class="homeCont_two_p3">
            LINKING THE WORL WITh DICTAL CULTURE
          </div>
        </div>
      </div>
    </div>
    <div class="homeCont_thr">
      <div class="homeCont_thr_left">
        <p class="homeCont_thr_left_p1">公司简介</p>
        <p class="homeCont_thr_left_p2">
          链玩是国内专业的数字文化综合电商服务平台。
          <span style="font-weight: 600"
            >平台致力于打造集版权经纪、NFR、数字品牌馆、元宇宙社交、创作者空间于一体的全链条
            数字生态体系。</span
          >总部设在杭州，在上海、香港等多地均设有分公司。平台相关资质、证件齐全，拥有海量知名IP、众多签约艺术家等资源，聚焦数字文化相关的文博文旅、动漫、科技潮玩潮流音乐、明星影视、游戏等文化内容的生产与供给，利用智能合约技术构建集IP运营、产品开发、数字权益确权于一体的数字资产创作、上链、确权、存证、流转、展示收藏等服务。链玩始终践行科技向善的理念，以平台为载体不断孵化优秀创作者IP,持续拓宽数字文化应用场景加速元宇宙和Web3.0的探索和商业化，赋能实体经济高质量发展。
        </p>
      </div>
      <div class="homeCont_thr_right_img"></div>
    </div>
    <div class="homeCont_for">
      <div class="homeCont_for_p1">公司文化</div>
      <div style="width: 100%; margin: auto">
        <div class="homeCont_for_waibox">
          <div
            :class="
              this.checks == 1 ? 'homeCont_for_boxxz' : 'homeCont_for_box'
            "
            @mouseenter="over(1)"
          >
            <p class="homeCont_for_box_p1">01</p>
            <p class="homeCont_for_box_heng"></p>
            <p class="homeCont_for_box_p2">公司口号</p>
            <p class="homeCont_for_box_p3">用数字文化</p>
            <p class="homeCont_for_box_p3">链接世界</p>
          </div>
          <div
            :class="
              this.checks == 2 ? 'homeCont_for_boxxz' : 'homeCont_for_box'
            "
            @mouseenter="over(2)"
          >
            <p class="homeCont_for_box_p1">02</p>
            <p class="homeCont_for_box_heng"></p>
            <p class="homeCont_for_box_p2">公司使命</p>
            <p class="homeCont_for_box_p3">让数字连通大众</p>
            <p class="homeCont_for_box_p3">让文化走向全球</p>
          </div>
          <div
            :class="
              this.checks == 3 ? 'homeCont_for_boxxz' : 'homeCont_for_box'
            "
            @mouseenter="over(3)"
          >
            <p class="homeCont_for_box_p1">03</p>
            <p class="homeCont_for_box_heng"></p>
            <p class="homeCont_for_box_p2">公司价值观</p>
            <p class="homeCont_for_box_p3">守正创新</p>
            <p class="homeCont_for_box_p3">与时俱进</p>
            <p class="homeCont_for_box_p3">敢为人先</p>
          </div>
        </div>
      </div>
    </div>
    <div class="homeCont_five">
      <img
        style="width: 100%; height: 100%"
        src="../assets/newImg/home/sy_box5_bj.jpg"
        alt=""
      />
      <div class="homeCont_five_p1">DIGITAL</div>
      <div class="homeCont_five_p11">CULTURE</div>
      <div class="homeCont_five_p2">五大核心业务</div>
      <div class="homeCont_five_p3">虚实联动赋能实体</div>
      <div class="homeCont_five_p4">LINKING THE WORLD WITH DIGITAL CULTURE</div>
      <div
        style="
          width: 1.1rem;
          height: 3px;
          background: rgb(16, 157, 240);
          position: absolute;
          color: #109df0;
          right: 135.4px;
          top: 4.69rem;
        "
      ></div>
    </div>
    <div class="homeCont_six">
      <div class="homeCont_six_box1">
        <div
          :class="
            this.contsixValue == 1 ? 'homeCont_six_huixz' : 'homeCont_six_hui'
          "
          @mouseover="contsix(1)"
        >
          <img
            v-if="this.contsixValue == 1"
            class="homeCont_six_imgtop"
            src="../assets/newImg/home/sixtop.jpg"
            alt=""
          />
          <img
            v-if="this.contsixValue == 1"
            style="width: 100%; height: 100%"
            src="../assets/newImg/home/six1.jpg"
            alt=""
          />
          <img
            v-else
            style="width: 100%; height: 100%"
            src="../assets/newImg/home/six1hui.jpg"
            alt=""
          />

          <div class="homeCont_six_p1">COPYRIGHT</div>
          <div class="homeCont_six_p2">BROKERAGE</div>
          <div class="homeCont_six_p3">版权经纪</div>
          <div class="homeCont_six_p4">
            数字化资产运营，主要包括版权确权、版权数字化和版权商用为版
            权实现商业最大化，为实体经济赋能内涵和附加值。
          </div>
        </div>
        <div
          :class="
            this.contsixValue == 2 ? 'homeCont_six_huixz' : 'homeCont_six_hui'
          "
          @mouseover="contsix(2)"
        >
          <img
            v-if="this.contsixValue == 2"
            class="homeCont_six_imgtop"
            src="../assets/newImg/home/sixtop.jpg"
            alt=""
          />
          <img
            v-if="this.contsixValue == 2"
            style="width: 100%; height: 100%"
            src="../assets/newImg/home/six2.jpg"
            alt=""
          />
          <img
            v-else
            style="width: 100%; height: 100%"
            src="../assets/newImg/home/six2hui.jpg"
            alt=""
          />
          <div class="homeCont_six_p1">NON FUNGIBLE</div>
          <div class="homeCont_six_p2">RIGHTS</div>
          <div class="homeCont_six_p3">NFR</div>
          <div class="homeCont_six_p4">
            用数字技术为虚拟与现实助力权益解决方案。
            保障数字经济与实体经济的相互融合，用数字赋能实体。
          </div>
        </div>
        <div
          :class="
            this.contsixValue == 3 ? 'homeCont_six_huixz' : 'homeCont_six_hui'
          "
          @mouseover="contsix(3)"
        >
          <img
            v-if="this.contsixValue == 3"
            class="homeCont_six_imgtop"
            src="../assets/newImg/home/sixtop.jpg"
            alt=""
          />
          <img
            v-if="this.contsixValue == 3"
            style="width: 100%; height: 100%"
            src="../assets/newImg/home/six3.jpg"
            alt=""
          />
          <img
            v-else
            style="width: 100%; height: 100%"
            src="../assets/newImg/home/six3hui.jpg"
            alt=""
          />
          <div class="homeCont_six_p1">NON FUNGIBLE</div>
          <div class="homeCont_six_p2">RIGHTS</div>
          <div class="homeCont_six_p3">数字品牌馆</div>
          <div class="homeCont_six_p4">
            设立城市、企业、文旅、文创、文博、地标等数字
            品牌馆，挖掘中国优秀文化资源，推动中华优
            秀传统文化创造性转化、创新性发展的同时，
            结合门票、电子优惠券、潮玩手办等权益赋能 实体经济高质量发展。
          </div>
        </div>
        <div
          :class="
            this.contsixValue == 4 ? 'homeCont_six_huixz' : 'homeCont_six_hui'
          "
          @mouseover="contsix(4)"
        >
          <img
            v-if="this.contsixValue == 4"
            class="homeCont_six_imgtop"
            src="../assets/newImg/home/sixtop.jpg"
            alt=""
          />
          <img
            v-if="this.contsixValue == 4"
            style="width: 100%; height: 100%"
            src="../assets/newImg/home/six4.jpg"
            alt=""
          />
          <img
            v-else
            style="width: 100%; height: 100%"
            src="../assets/newImg/home/six4hui.jpg"
            alt=""
          />
          <div class="homeCont_six_p1">DIGITAL CULTURE</div>
          <div class="homeCont_six_p2">MUSEUM</div>
          <div class="homeCont_six_p3">元宇宙社交</div>
          <div class="homeCont_six_p4">
            通过区块链、数字李生、虚拟现实、3D渲染等数
            字化技术手段，打造全方位、多立体、互动沉浸
            式体验，从游戏、社交等泛娱乐体验,逐步延伸
            到各种现实场景的线上线下一体化，满足人们
            对极致娱乐体验和效率提升的需求。
          </div>
        </div>
        <div
          :class="
            this.contsixValue == 5 ? 'homeCont_six_huixz' : 'homeCont_six_hui'
          "
          @mouseover="contsix(5)"
        >
          <img
            v-if="this.contsixValue == 5"
            class="homeCont_six_imgtop"
            src="../assets/newImg/home/sixtop.jpg"
            alt=""
          />
          <img
            v-if="this.contsixValue == 5"
            style="width: 100%; height: 100%"
            src="../assets/newImg/home/six5.jpg"
            alt=""
          />
          <img
            v-else
            style="width: 100%; height: 100%"
            src="../assets/newImg/home/six5hui.jpg"
            alt=""
          />
          <div class="homeCont_six_p1">CREATOR</div>
          <div class="homeCont_six_p2">SPACE</div>
          <div class="homeCont_six_p3">创作者空间</div>
          <div class="homeCont_six_p4">
            IP工厂+IP超市，为创作者提供创作、铸造、交
            易、撮合等一站式服务，是WEB3应用场景之一
          </div>
        </div>
      </div>
      <div class="homeCont_six_box2">
        <div
          style="
            width: 12.1rem;
            height: 12.1rem;
            position: absolute;
            top: 3.6rem;
            left: 3.8rem;
          "
        >
          <img
            style="width: 100%; height: 100%"
            src="../assets/newImg//home/sy_box6_bj.png"
            alt=""
          />
        </div>
        <div style="position: absolute; top: 0.9rem">
          <img
            style="width: 100%; height: 100%"
            src="../assets/newImg//home/sy_box6_bj2.png"
            alt=""
          />
        </div>
        <div
          style="
            width: 8.7rem;
            height: 8.7rem;
            position: absolute;
            top: 5.3rem;
            left: 5.5rem;
          "
        >
          <img
            style="width: 100%; height: 100%"
            src="../assets/newImg//home/sy_box6_bj3.png"
            alt=""
          />
        </div>
        <div
          style="
            position: absolute;
            top: 1.24rem;
            left: 0rem;
            width: 0.83rem;
            height: 4.5rem;
          "
        >
          <img
            style="width: 100%; height: 100%"
            src="../assets/newImg/home/sy_box6_left.jpg"
            alt=""
          />
        </div>
        <div
          style="
            position: absolute;
            right: 4rem;
            bottom: 1.4rem;
            width: 0.73rem;
            height: 3.5rem;
          "
        >
          <img
            style="width: 100%; height: 100%"
            src="../assets/newImg/home/sy_box6_right.jpg"
            alt=""
          />
        </div>
        <div
          style="
            position: absolute;
            top: 2.4rem;
            left: 0.35rem;
            width: 0.83rem;
            height: 4.5rem;
          "
        >
          <div class="homeCont_six_box2_p1">DIGITAL</div>
          <div class="homeCont_six_box2_p1">DIGITAL</div>
          <div class="homeCont_six_box2_p1">PLATFORM</div>
        </div>
        <div>
          <div class="homeCont_six_box2_p2">DIGITAL</div>
        </div>
      </div>
      <div class="homeCont_six_box3">
        <div style="padding-top: 1.4rem">
          <div class="homeCont_six_box3_p1">JOIN</div>
          <div class="homeCont_six_box3_p1">THE CREATOR</div>
          <div class="homeCont_six_box3_p1">ECOLOGY</div>
          <div class="homeCont_six_box3_p2">加入创作者空间</div>
          <div class="homeCont_six_box3_p3">
            L O O K &nbsp; F O R W A R D &nbsp; T O &nbsp; Y O U R &nbsp; J O I
            N I N G
          </div>
        </div>
        <div style="width: 1.8rem; height: 0.44rem; margin-top: 0.7rem">
          <img
            style="width: 100%; height: 100%"
            src="../assets/newImg/home/sy_box_joinus.jpg"
            alt=""
          />
        </div>
        <div
          style="
            width: 4.6rem;
            height: 5.6rem;
            position: absolute;
            bottom: 0;
            left: 5.6rem;
          "
        >
          <img
            style="width: 100%; height: 100%"
            src="../assets/newImg/home/sy_box_hua.png"
            alt=""
          />
        </div>
        <div style="position: absolute; left: 11rem; top: 1.6rem">
          <div class="homeCont_six_box3_p4">LINKING</div>
          <div class="homeCont_six_box3_p4">THE WORLD WITH</div>
          <div class="homeCont_six_box3_p4">DIGITAL CULTURE</div>
          <div class="homeCont_six_box3_xian"></div>
          <div
            style="
              width: 2.16rem;
              height: 7.15rem;
              position: absolute;
              top: 3.4rem;
              left: 0rem;
              z-index: 99;
            "
          >
            <img
              style="width: 100%; height: 100%"
              src="../assets/newImg/home/sy_foot_about.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="homeCont_ba">
      <img
        style="width: 100%; height: 100%"
        src="../assets/newImg/home/sy_foot.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      checks: 1,
      contsixValue: 1,
      SYvedio: true,
    };
  },
  created() {
    // this.play();
    this.clickplay();
  },
  mounted() {
    // 切换页面时滚动条自动滚动到顶部
    window.scrollTo(0, 0);
  },
  computed: {},
  methods: {
    over(index) {
      console.log(index, "checks");
      this.checks = index;
    },
    contsix(index) {
      console.log(index, "contsixValue");
      this.contsixValue = index;
    },
    clickSY() {
      this.SYvedio = !this.SYvedio;
    },
  },
};
</script>
<style lang="less" scoped>
#home {
  height: 100%;
  width: 100%;
  min-width: 1300px;
  overflow: hidden;
  background: rgb(248, 251, 255);

  .swiperImg {
    margin: auto;
    position: relative;
    width: 100%;
    height: 731.1px;
    min-width: 1300px;
    overflow: hidden;
    background-image: url("../assets/img/appleTV.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .swiperImg1 {
    margin: auto;
    position: relative;
    width: 100%;
    height: 731.1px;
    min-width: 1300px;
    // background-image: url("../assets/img/官网banner.png");
    background-size: cover;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .homeCont_two {
    margin: auto;
    position: relative;
    width: 100%;
    min-width: 1300px;
    overflow: hidden;
    //background-image: url("../assets/newImg/home/sy_box2_bj.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    .homeCont_two_p1 {
      position: absolute;
      top: 2.954rem;
      left: 1.5rem;
      color: #fff;
      font-size: 0.7rem;
      font-weight: 600;
      line-height: 0.81rem;
      font-family: "DIN-Black";
    }
    .homeCont_two_p2 {
      position: absolute;
      left: 1.5rem;
      top: 6.9rem;
      font-size: 0.9rem;
      color: #fff;
      font-family: "DIN-Black";
      font-weight: 600;
    }
    .homeCont_two_p3 {
      font-size: 0.2rem;
      position: absolute;
      left: 1.5rem;
      color: #fff;
      top: 8rem;
      font-weight: 600;
      font-family: "DIN-Black";
    }
  }
  .homeCont_thr {
    margin: auto;
    position: relative;
    width: 100%;
    min-width: 1300px;
    overflow: hidden;

    display: flex;
    .homeCont_thr_left {
      width: 7.8rem;
      background: #000;
      color: #fff;
      padding: 1.39rem 1rem 1rem 1rem;
      box-sizing: border-box;
      .homeCont_thr_left_p1 {
        font-size: 0.33rem;
        font-family: "DIN-Black";
      }
      .homeCont_thr_left_p2 {
        text-indent: 2em;
        margin-top: 0.66rem;
        font-size: 0.16rem;
        line-height: 0.47rem;
        font-family: "DIN-Black";
      }
    }
    .homeCont_thr_right_img {
      width: calc(100% - 7.8rem);
      background-image: url("../assets/newImg/home/sy_box3_bj.jpg");
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .homeCont_for {
    margin: auto;
    position: relative;
    width: 100%;
    height: 646.5px;
    min-width: 1300px;
    overflow: hidden;
    // background-image: url("../assets/img/官网banner.png");
    background-color: #000;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    color: #fff;
    text-align: center;
    .homeCont_for_p1 {
      margin-top: 100.1px;
      font-size: 33.8px;
      font-family: "DIN-Black";
    }
    .homeCont_for_waibox {
      display: flex;
      width: 988.4px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
      justify-content: space-between;
      margin: auto;
      margin-top: 81.2px;
    }
    .homeCont_for_boxxz {
      width: 283px;
      height: 283px;
      text-align: center;
      background: rgb(234, 58, 244);
      color: #000;
      .homeCont_for_box_p1 {
        -webkit-text-stroke: 1px black;
        -webkit-text-fill-color: transparent;
        font-size: 60.9px;
        margin-top: 13.5px;
        font-family: "DIN-Black";
      }
      .homeCont_for_box_heng {
        width: 16.9px;
        height: 4.7px;
        background: #000;
        margin: auto;
        margin-bottom: 13.5px;
      }
      .homeCont_for_box_p2 {
        font-size: 16.2px;
        font-weight: 600;
        font-family: "DIN-Black";
        margin-bottom: 10.1px;
      }
      .homeCont_for_box_p3 {
        font-size: 13.5px;
        line-height: 33.8px;
        font-family: "DIN-Black";
      }
    }
    .homeCont_for_box {
      width: 283px;
      height: 283px;
      text-align: center;
      border: 1px solid #ccc;
      .homeCont_for_box_p1 {
        font-size: 60.9px;
        margin-top: 13.5px;
        -webkit-text-stroke: 1px #fff;
        -webkit-text-fill-color: transparent;
        font-family: "DIN-Black";
      }
      .homeCont_for_box_heng {
        width: 16.9px;
        height: 4.7px;
        background: #fff;
        margin: auto;
        margin-bottom: 13.5px;
      }
      .homeCont_for_box_p2 {
        font-size: 18.3px;
        font-weight: 600;
        font-family: "DIN-Black";
        margin-bottom: 10.1px;
      }
      .homeCont_for_box_p3 {
        font-size: 13.5px;
        line-height: 33.8px;
        font-family: "DIN-Black";
      }
    }
  }
  .homeCont_five {
    margin: auto;
    position: relative;
    width: 100%;
    min-width: 1300px;
    .homeCont_five_p1 {
      position: absolute;
      top: 1.95rem;
      left: 1.5rem;
      // margin: 132px 0 0 102.2px;
      font-size: 0.9rem;
      color: #fff;
      font-weight: 600;
      font-family: "DIN-Black";
    }
    .homeCont_five_p11 {
      position: absolute;
      top: 3rem;
      left: 1.5rem;
      // margin: 0 0 0 102.2px;
      font-size: 0.9rem;
      color: #fff;
      font-weight: 600;
      font-family: "DIN-Black";
    }
    .homeCont_five_p2 {
      position: absolute;
      font-size: 0.5rem;
      color: rgb(16, 157, 240);
      right: 1.374rem;
      top: 1.76rem;
      font-family: "DIN-Black";
    }
    .homeCont_five_p3 {
      position: absolute;
      right: 1.354rem;
      top: 2.586rem;
      font-size: 0.7rem;
      color: #fff;
      font-family: "DIN-Black";
    }
    .homeCont_five_p4 {
      position: absolute;
      right: 135.4px;
      top: 4.09rem;
      font-weight: 600;
      font-size: 0.2rem;
      color: #fff;
      font-family: "DIN-Black";
    }
  }

  .homeCont_six {
    // margin-left: 2.3rem;
    margin-top: -3.3rem;
    position: relative;

    .homeCont_six_box1 {
      height: 6.7rem;
      display: flex;

      .homeCont_six_hui {
        display: flex;
        //width: 3.2rem;
        width: calc((100% - 4.2rem) / 4);
        transition: width 0.5s, transform 0.5s;
        position: relative;

        .homeCont_six_p1 {
          position: absolute;
          top: 0.57rem;
          left: 0.37rem;
          font-size: 0.35rem;
          color: #fff;
          font-weight: 600;
          font-family: "DIN-Black";
        }
        .homeCont_six_p2 {
          position: absolute;
          top: 0.97rem;
          left: 0.37rem;
          font-size: 0.35rem;
          color: #fff;
          font-weight: 600;
          font-family: "DIN-Black";
        }
        .homeCont_six_p3 {
          position: absolute;
          top: 1.77rem;
          left: 0.37rem;
          font-size: 0.34rem;
          color: #fff;
        }
        .homeCont_six_p4 {
          position: absolute;
          bottom: 1rem;
          left: 0.37rem;
          right: 0.37rem;
          font-size: 0.12rem;
          color: #fff;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
        }
      }
      .homeCont_six_huixz {
        display: flex;
        width: 4.2rem;
        transition: width 0.5s, transform 0.5s;
        position: relative;

        .homeCont_six_imgtop {
          width: 4.2rem;
          height: 0.6rem;
          position: absolute;
          top: -0.6rem;
        }
        .homeCont_six_p1 {
          position: absolute;
          top: 0.57rem;
          left: 0.37rem;
          font-size: 0.35rem;
          color: #fff;
          font-weight: 600;
          font-family: "DIN-Black";
        }
        .homeCont_six_p2 {
          position: absolute;
          top: 0.97rem;
          left: 0.37rem;
          font-size: 0.35rem;
          color: #fff;
          font-weight: 600;
          font-family: "DIN-Black";
        }
        .homeCont_six_p3 {
          position: absolute;
          top: 1.77rem;
          left: 0.37rem;
          font-size: 0.34rem;
          color: #fff;
        }
        .homeCont_six_p4 {
          position: absolute;
          bottom: 1rem;
          left: 0.37rem;
          right: 0.37rem;
          font-size: 0.12rem;
          color: #fff;
        }
      }
    }
    .homeCont_six_box2 {
      height: 18rem;
      background: #000;
      position: relative;
      overflow: hidden;

      .homeCont_six_box2_p1 {
        font-size: 0.7rem;
        font-weight: 600;
        font-family: "DIN-Black";
        color: #fff;
      }
      .homeCont_six_box2_p2 {
        position: absolute;
        right: -0.9rem;
        top: 2rem;
        font-size: 3.1rem;
        font-family: "DIN-Black";
        font-weight: 600;
        color: rgb(233, 59, 244);
        writing-mode: tb-rl;
      }
    }
    .homeCont_six_box3 {
      height: 7.6rem;
      background: #fff;
      position: relative;
      margin-left: 2.3rem;
      .homeCont_six_box3_p1 {
        font-size: 0.6rem;
        font-weight: 600;
        font-family: "DIN-Black";
      }
      .homeCont_six_box3_p2 {
        font-size: 0.63rem;
        margin-top: 0.4rem;
      }
      .homeCont_six_box3_p3 {
        font-size: 0.14rem;
        margin-top: 0.1rem;
        font-family: "DIN-Black";
      }
      .homeCont_six_box3_p4 {
        font-size: 0.24rem;
        line-height: 0.4rem;
        font-family: "DIN-Black";
      }
      .homeCont_six_box3_xian {
        width: 0.86rem;
        height: 0.01rem;
        border-bottom: 1px solid #000;
        margin-top: 0.2rem;
      }
    }
  }
  .homeCont_ba {
    margin: auto;
    position: relative;
    width: 100%;
    float: right;
    min-width: 1300px;
    background-image: url("../assets/newImg/home/sy_foot.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
  }

  video {
    object-fit: fill;
  }
}
</style>
