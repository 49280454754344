import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import less from "less";
Vue.use(less);
import ElementUI from "element-ui";
Vue.use(ElementUI);
import "element-ui/lib/theme-chalk/index.css";
// import "lib-flexible/flexible.js";
Vue.config.productionTip = false;
import axios from "axios";
import './rem'
// import '../flexible'

Vue.prototype.$axios = axios;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
