<template>
  <div id="home">
    <div style="margin: auto">
      <div class="cate_banner">
        <div>
          <div class="cate_banner_p">资 质 齐 全 &nbsp 行 业 先 锋</div>
        </div>
      </div>
      <div class="cate_news">
        <div class="cate_news_box1">
          <div class="cate_news_box1_img"></div>
          <div class="cate_news_box1_flex_box">
            <div class="cate_news_box1_p">| 资质证书 |</div>
            <div class="cate_news_box1_span">QUALIFICATIONS</div>
          </div>
        </div>
        <div class="cate_news_box2">
          <div class="cate_news_box2_p">
            <p>区块链备案：浙网信备33010522548654110011号</p>
            <p>增值电信业务经营许可证(含ICP及ED业务许可)：浙B2-20210544</p>
            <p>ICP备案：浙ICP备19017819号-1</p>
            <p>浙公网安备：33010502007114号</p>
          </div>
          <div class="cate_news_box2_p">
            <p>网络文化经营许可证：浙网文(2022)1210-055号</p>
            <p>出版物经营许可证：浙杭拱零字第22017号</p>
            <p>拍卖经营批准证书：浙杭3304571100002020</p>
            <p>计算机软件著作权登记证书：软著登字第8040558号</p>
          </div>
        </div>
        <div class="cate_news_box3">
          <div class="cate_news_box3_img"></div>
        </div>
        <div class="cate_news_box4">
          <div class="cate_news_box4_img">
            <!-- <p class="cate_news_box4_img_p">
            | 链玩荣誉证书 | <span style="color: #0fc1ff">HONOR</span>
          </p> -->
            <div class="cate_news_box4_flex_box">
              <div class="cate_news_box4_p">| 链玩荣誉证书 |</div>
              <div class="cate_news_box4_span">HONOR</div>
            </div>
          </div>
          <div class="cate_news_box4_imgbox2">
            <div class="cate_news_box4_imgbox2img"></div>
          </div>
          <div class="cate_news_box4_img" style="margin-top: 121.8px">
            <!-- <p class="cate_news_box4_img_p">
            | 链玩荣誉奖牌 | <span style="color: #0fc1ff">HONOR</span>
          </p> -->
            <div class="cate_news_box4_flex_box">
              <div class="cate_news_box4_p">| 链玩荣誉奖牌 |</div>
              <div class="cate_news_box4_span">HONOR</div>
            </div>
          </div>
          <div class="cate_news_box4_imgbox3">
            <div class="cate_news_box4_imgbox3_img"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="cate_footer">
      <div class="cate_footer_flex">
        <div class="footer_flex_leftbox">
          <img
            style="width: 100%; height: 100%"
            src="../assets/newImg/certificate/zs_footleft.jpg"
            alt=""
          />
        </div>
        <div class="footer_flex_rightimg">
          <img
            style="width: 100%; height: 100%"
            src="../assets/newImg/certificate/zs_fottright.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {
    // 切换页面时滚动条自动滚动到顶部
    window.scrollTo(0, 0);
  },
  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped>
#home {
  height: 100%;
  width: 100%;
  min-width: 1300px;
  .cate_banner {
    margin: auto;
    margin-top: 70px;
    position: relative;
    width: 100%;
    height: 300px;
    overflow: hidden;
    background-image: url("../assets/newImg/certificate/zs_banner.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .cate_banner_p {
      font-weight: 600;
      color: #fff;
      font-size: 58.9px;
      font-family: "DIN-Black";
      text-align: center;
      line-height: 300px;
    }
  }
  .cate_news {
    width: 100%;
    height: 100%;
    background: #000;
    padding-bottom: 149.6px;
    .cate_news_box1 {
      padding-top: 121.8px;
      margin: auto;

      .cate_news_box1_img {
        width: 538.2px;
        height: 74.5px;
        background-image: url("../assets/newImg/certificate/lianwanbj.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin: auto;
      }
      .cate_news_box1_flex_box {
        text-align: center;
        width: 677px;
        display: flex;
        margin: auto;
        margin-top: -74.5px;
        .cate_news_box1_p {
          font-size: 44.7px;
          color: #fff;
          line-height: 67.7px;
          font-family: "CFZJ";
        }
        .cate_news_box1_span {
          font-size: 46.1px;
          margin-left: 6.77px;
          color: #0fc1ff;
          font-weight: 600;
        }
      }
    }
    .cate_news_box2 {
      width: 1300px;
      display: flex;
      margin: auto;
      line-height: 21.7px;
      justify-content: space-around;
      font-size: 13.5px;
      .cate_news_box2_p {
        color: #fff;
        margin-top: 86px;
      }
    }
    .cate_news_box3 {
      width: 1300px;
      margin: auto;
      .cate_news_box3_img {
        width: 671px;
        height: 753px;
        background-image: url("../assets/newImg/certificate/zs_zs1.png");
        background-size: cover;
        background-repeat: no-repeat;
        margin: auto;
        margin-top: 63px;
      }
    }
    .cate_news_box4 {
      width: 1300px;
      margin: auto;
      padding-top: 124px;
      .cate_news_box4_img {
        width: 538.2px;
        height: 74.5px;
        background-image: url("../assets/newImg/certificate/lianwanbj.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin: auto;
      }
      .cate_news_box4_flex_box {
        text-align: center;
        width: 677px;
        display: flex;
        margin: auto;
        // margin-top: -74.5px;
        .cate_news_box4_p {
          font-size: 44.7px;
          color: #fff;
          font-family: "CFZJ";
          line-height: 73.1px;
        }
        .cate_news_box4_span {
          font-weight: 600;
          font-size: 54.5px;
          margin-left: 6.77px;
          color: #0fc1ff;
        }
      }
      .cate_news_box4_imgbox2 {
        margin: auto;
        margin-top: 89.3px;
        .cate_news_box4_imgbox2img {
          width: 821.9px;
          height: 375px;
          background-image: url("../assets/newImg/certificate/zs_zs2.png");
          background-size: cover;
          background-repeat: no-repeat;
          margin: auto;
        }
      }
      .cate_news_box4_imgbox3 {
        margin-top: 89.3px;
        width: 1300px;
        .cate_news_box4_imgbox3_img {
          width: 575.4px;
          height: 404.8px;
          background-image: url("../assets/newImg/certificate/zs_zs3.png");
          background-size: cover;
          background-repeat: no-repeat;
          margin: auto;
        }
      }
    }
  }
  .cate_footer {
    width: 100%;
    .cate_footer_flex {
      display: flex;
      .footer_flex_leftbox {
        width: 47%;
      }
      .footer_flex_rightimg {
        width: calc(100% - 47%);
      }
    }
  }
}
</style>
