<template>
  <div id="business">
    <div class="business_banner">
      <div class="business_banner_p">IP META</div>
    </div>
    <!-- 1 -->
    <div class="business_contbox">
      <div class="business_contbox_bj"></div>
      <div class="business_contbox_bj_right"></div>
      <div class="business_contbox_flex">
        <div style="display: flex">
          <div style="padding-top: 1rem">
            <span class="business_contbox_flex_p">创世典藏馆 </span
            ><span style="color: #fff; font-size: 28px"> / &nbsp </span>
            <span class="business_contbox_flex_span"> 01</span>
          </div>
          <div class="business_contbox_flex_img" style="width: 798.9px"></div>
        </div>
        <div>
          <div class="business_contbox_flex_p1">GENESIS COLLECTION</div>
        </div>
        <div class="business_contbox_contflex">
          <div class="business_contbox_contflex_left">
            <div class="business_contbox_contflex_leftbox1">
              <div class="business_contbox_contflex_left_img"></div>
              <div class="business_contbox_contflex_left_p1">
                版权方:毕萌(潮玩数智平台)
              </div>
              <div class="business_contbox_contflex_left_p2">
                版权方简介:杭州毕萌数智科技有限公司致力于文化创意生态发展，是一家集设计、研发、全网营销于一体的高科技企业。旗下的产品类别涵盖
                潮玩、手办、盲盒、IP及周边等文创产品，以及智能物联机器人、无人零售
                系统解决方案;着力渠道下沉，以线下终端为流量入口，持续为IP品牌赋
                能，重构人、货、场新零售。
              </div>
            </div>
            <div>
              <div class="box2_img"></div>
            </div>
            <div>
              <div class="box3_bj">
                <div class="box3_banner">
                  <div class="block">
                    <el-carousel
                      height="334.4px"
                      ref="carousel"
                      indicator-position="none"
                      @change="changeItem"
                    >
                      <el-carousel-item
                        v-for="(item, index) in MFArr"
                        :key="index"
                      >
                        <img
                          style="width: 100%; height: 100%"
                          :src="item.img"
                          alt=""
                        />
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
              </div>
              <div class="box3_banner_btnbox">
                <div
                  @click="clickBtn(0)"
                  :class="
                    this.active == 0 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
                <div
                  @click="clickBtn(1)"
                  :class="
                    this.active == 1 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
                <div
                  @click="clickBtn(2)"
                  :class="
                    this.active == 2 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
                <div
                  @click="clickBtn(3)"
                  :class="
                    this.active == 3 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
                <div
                  @click="clickBtn(4)"
                  :class="
                    this.active == 4 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底线 -->
    <div class="business_contbox_footimg">
      <div class="business_contbox_footimg1"></div>
    </div>
    <!-- 2 -->
    <div class="business_contbox">
      <div class="business_contbox_bj"></div>
      <div class="business_contbox_bj1"></div>
      <div class="business_contbox_flex">
        <div style="display: flex">
          <div style="padding-top: 1rem">
            <span class="business_contbox_flex_p">版权品品牌馆 </span
            ><span style="color: #fff; font-size: 28px"> / &nbsp </span>
            <span class="business_contbox_flex_span"> 02</span>
          </div>
          <div class="business_contbox_flex_img" style="width: 744.7px"></div>
        </div>
        <div>
          <div class="business_contbox_flex_p1">COPYRIGHT BRAND HALL</div>
        </div>
        <div class="business_contbox_contflex">
          <div class="business_contbox_contflex_left">
            <div>
              <div class="box31_bj">
                <div class="box3_banner">
                  <div class="block">
                    <el-carousel
                      height="334.4px"
                      ref="carousel2"
                      indicator-position="none"
                      @change="changeItem2"
                    >
                      <el-carousel-item
                        v-for="(item, index) in NHArr"
                        :key="index"
                      >
                        <img
                          style="width: 100%; height: 100%"
                          :src="item.img"
                          alt=""
                        />
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
              </div>
              <div class="box3_banner_btnbox">
                <div
                  @click="clickBtn2(0)"
                  :class="
                    this.active2 == 0 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
                <div
                  @click="clickBtn2(1)"
                  :class="
                    this.active2 == 1 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
                <div
                  @click="clickBtn2(2)"
                  :class="
                    this.active2 == 2 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
                <div
                  @click="clickBtn2(3)"
                  :class="
                    this.active2 == 3 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
              </div>
            </div>
            <div>
              <div class="box21_img"></div>
            </div>
            <div class="business_contbox_contflex_leftbox1">
              <div class="business_contbox_contflex_left_img2"></div>
              <div
                class="business_contbox_contflex_left_p1"
                style="margin-top: 30px"
              >
                版权方:北京奇果创想文化传媒有限公司
              </div>
              <div
                class="business_contbox_contflex_left_p2"
                style="text-align: left"
              >
                版权方简介:北京奇果创想文化传媒有限公司创建于2012年,一直专注
                于品牌多方位IP宣传解决方案的创新与发展。目前已为100余家知名企
                业提供过多媒体相关设计服务,并取得了较高的评价!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底线 -->
    <div class="business_contbox_footimg">
      <div class="business_contbox_footimg1"></div>
    </div>
    <!-- 3 -->
    <div class="business_contbox">
      <div class="business_contbox_bj"></div>
      <div class="business_contbox_bj_right"></div>
      <div class="business_contbox_flex">
        <div style="display: flex">
          <div style="padding-top: 1rem">
            <span class="business_contbox_flex_p">文旅品牌馆 </span
            ><span style="color: #fff; font-size: 28px"> / &nbsp </span>
            <span class="business_contbox_flex_span"> 03</span>
          </div>
          <div class="business_contbox_flex_img" style="width: 798.8px"></div>
        </div>
        <div>
          <div class="business_contbox_flex_p1">CULTURAL TOURISM</div>
        </div>
        <div class="business_contbox_contflex">
          <div class="business_contbox_contflex_left">
            <div class="business_contbox_contflex_leftbox1">
              <div class="business_contbox_contflex_left_img3"></div>
              <div
                class="business_contbox_contflex_left_p1"
                style="margin-top: 30px"
              >
                版权方:普陀山
              </div>
              <div
                class="business_contbox_contflex_left_p2"
                style="text-align: left"
              >
                版权方简介:普陀山是著名的5A级景区，国务院批准的《普陀山风景名胜
                区总体规划》划分风景小区为南天门、普济寺、潮音洞至观音跳、西天门
                至风洞嘴、法雨寺、佛顶山慧济寺、飞沙岙至梵音洞、海澄庵后岙沙、百步
                沙千步沙(以.上在普陀本岛)、洛迦山和邻近朱家尖的白山、十里金沙、樟
                湾等十三个。属普陀山行政管辖区的有十个景区。
              </div>
            </div>
            <div>
              <div class="box2_img"></div>
            </div>
            <div>
              <div class="box3_bj">
                <div class="box3_banner">
                  <div class="block">
                    <el-carousel
                      height="334.4px"
                      ref="carousel3"
                      indicator-position="none"
                      @change="changeItem3"
                    >
                      <el-carousel-item
                        v-for="(item, index) in PTSArr"
                        :key="index"
                      >
                        <img
                          style="width: 100%; height: 100%"
                          :src="item.img"
                          alt=""
                        />
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
              </div>
              <div class="box3_banner_btnbox">
                <div
                  @click="clickBtn3(0)"
                  :class="
                    this.active3 == 0 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
                <div
                  @click="clickBtn3(1)"
                  :class="
                    this.active3 == 1 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
                <div
                  @click="clickBtn3(2)"
                  :class="
                    this.active3 == 2 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
                <div
                  @click="clickBtn3(3)"
                  :class="
                    this.active3 == 3 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
                <div
                  @click="clickBtn3(4)"
                  :class="
                    this.active3 == 4 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底线 -->
    <div class="business_contbox_footimg">
      <div class="business_contbox_footimg1"></div>
    </div>
    <!-- 4 -->
    <div class="business_contbox">
      <div class="business_contbox_bj"></div>
      <div class="business_contbox_bj1"></div>
      <div class="business_contbox_flex">
        <div style="display: flex">
          <div style="padding-top: 1rem">
            <span class="business_contbox_flex_p">敦煌系列文创IP </span
            ><span style="color: #fff; font-size: 28px"> / &nbsp </span>
            <span class="business_contbox_flex_span"> 04</span>
          </div>
          <div class="business_contbox_flex_img" style="width: 717.6px"></div>
        </div>
        <div>
          <div class="business_contbox_flex_p1">DUNHUANG SERIES</div>
        </div>
        <div class="business_contbox_contflex">
          <div class="business_contbox_contflex_left">
            <div>
              <div class="box31_bj">
                <div class="box3_banner">
                  <div class="block">
                    <el-carousel
                      height="334.4px"
                      ref="carousel4"
                      indicator-position="none"
                      @change="changeItem4"
                    >
                      <el-carousel-item
                        v-for="(item, index) in DHArr"
                        :key="index"
                      >
                        <img
                          style="width: 100%; height: 100%"
                          :src="item.img"
                          alt=""
                        />
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
              </div>
              <div class="box3_banner_btnbox">
                <div
                  @click="clickBtn4(0)"
                  :class="
                    this.active4 == 0 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
                <div
                  @click="clickBtn4(1)"
                  :class="
                    this.active4 == 1 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
              </div>
            </div>
            <div>
              <div class="box21_img"></div>
            </div>
            <div class="business_contbox_contflex_leftbox1">
              <div class="business_contbox_contflex_left_img4"></div>
              <div
                class="business_contbox_contflex_left_p1"
                style="margin-top: 30px"
              >
                版权方:敦煌文旅
              </div>
              <div
                class="business_contbox_contflex_left_p2"
                style="text-align: left"
              >
                版权方简介:敦煌文旅集团有限公司是按照敦煌市委、市政府全面深化
                国企国资改革的战略部署，在整合全市国有资产、国有资源国有企业的
                基础上，组建形成的市属国有独资企业。公司注册资本1亿元，集团旗下
                有敦煌市鸣沙山月牙泉旅游发展有限公司、敦煌智慧旅游有限责任公司
                、敦煌工美文化创意有限责任公司、敦煌文旅交通有限公司、敦煌文旅玉
                i关景区运营管理公司、敦煌文旅雅丹景区运营管理公司、敦煌文旅沙
                州夜市景区运营管理公司、敦煌宾馆有限责任公司、敦煌丝路戎通国际
                旅行社有限公司等多家子公司。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底线 -->
    <div class="business_contbox_footimg">
      <div class="business_contbox_footimg1"></div>
    </div>
    <!-- 5 -->
    <div class="business_contbox">
      <div class="business_contbox_bj"></div>
      <div class="business_contbox_bj_right"></div>
      <div class="business_contbox_flex">
        <div style="display: flex">
          <div style="padding-top: 1rem">
            <span class="business_contbox_flex_p">神兽养成记系列游戏IP </span
            ><span style="color: #fff; font-size: 28px"> / &nbsp </span>
            <span class="business_contbox_flex_span"> 05</span>
          </div>
          <div class="business_contbox_flex_img" style="width: 582.2px"></div>
        </div>
        <div>
          <div class="business_contbox_flex_p1">MYTHICAL ANIMALS</div>
        </div>
        <div class="business_contbox_contflex">
          <div class="business_contbox_contflex_left">
            <div class="business_contbox_contflex_leftbox1">
              <div class="business_contbox_contflex_left_img5"></div>
              <div class="business_contbox_contflex_left_p1">
                版权方:妙聚网络科技有限公司
              </div>
              <div
                class="business_contbox_contflex_left_p2"
                style="text-align: left"
              >
                发行方简介:妙聚网络科技有限公司(股票代码:094860.KQ)成立于
                2013年，是-家以互联网文化传媒为主业的科技公司妙聚获得国家队
                的大力支持，由招商局资本和电广传媒共同投资八亿元，2019年收购韩
                国KOSDAQ上市公司。公司旗下拥有“乐都网”、“优思行”、"07073”三大
                品牌。总部位于中国杭州，在北京、上海、广州、深圳、成都、沈阳等地拥有
                分支机构，在新加坡设有海外总部，并在首尔拥有一支运营团队。
              </div>
            </div>
            <div>
              <div class="box2_img"></div>
            </div>
            <div>
              <div class="box3_bj">
                <div class="box3_banner">
                  <div class="block">
                    <el-carousel
                      height="334.4px"
                      ref="carousel5"
                      indicator-position="none"
                      @change="changeItem5"
                    >
                      <el-carousel-item
                        v-for="(item, index) in SSArr"
                        :key="index"
                      >
                        <img
                          style="width: 100%; height: 100%"
                          :src="item.img"
                          alt=""
                        />
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
              </div>
              <div class="box3_banner_btnbox">
                <div
                  @click="clickBtn5(0)"
                  :class="
                    this.active5 == 0 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
                <div
                  @click="clickBtn5(1)"
                  :class="
                    this.active5 == 1 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
                <div
                  @click="clickBtn5(2)"
                  :class="
                    this.active5 == 2 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
                <div
                  @click="clickBtn5(3)"
                  :class="
                    this.active5 == 3 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底线 -->
    <div class="business_contbox_footimg">
      <div class="business_contbox_footimg1"></div>
    </div>
    <!-- 6 -->
    <div class="business_contbox">
      <div class="business_contbox_bj"></div>
      <div class="business_contbox_bj1"></div>
      <div class="business_contbox_flex">
        <div style="display: flex">
          <div style="padding-top: 1rem">
            <span class="business_contbox_flex_p">八仙过海系列文旅IP </span
            ><span style="color: #fff; font-size: 28px"> / &nbsp </span>
            <span class="business_contbox_flex_span"> 06</span>
          </div>
          <div class="business_contbox_flex_img" style="width: 629.6px"></div>
        </div>
        <div>
          <div class="business_contbox_flex_p1">EIGHT IMMORTALS</div>
        </div>
        <div class="business_contbox_contflex">
          <div class="business_contbox_contflex_left">
            <div>
              <div class="box31_bj">
                <div class="box3_banner">
                  <div class="block">
                    <el-carousel
                      height="334.4px"
                      ref="carousel6"
                      indicator-position="none"
                      @change="changeItem6"
                    >
                      <el-carousel-item
                        v-for="(item, index) in BXArr"
                        :key="index"
                      >
                        <img
                          style="width: 100%; height: 100%"
                          :src="item.img"
                          alt=""
                        />
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
              </div>
              <div class="box3_banner_btnbox">
                <div
                  @click="clickBtn6(0)"
                  :class="
                    this.active6 == 0 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
                <div
                  @click="clickBtn6(1)"
                  :class="
                    this.active6 == 1 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
                <div
                  @click="clickBtn6(2)"
                  :class="
                    this.active6 == 2 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
                <div
                  @click="clickBtn6(3)"
                  :class="
                    this.active6 == 3 ? 'box3_banner_btnxz' : 'box3_banner_btn'
                  "
                ></div>
              </div>
            </div>
            <div>
              <div class="box21_img"></div>
            </div>
            <div class="business_contbox_contflex_leftbox1">
              <div class="business_contbox_contflex_left_img6"></div>
              <div
                class="business_contbox_contflex_left_p1"
                style="margin-top: 30px"
              >
                发行方:蓬莱阁景区
              </div>
              <div
                class="business_contbox_contflex_left_p2"
                style="text-align: left"
              >
                发行方简介:蓬莱阁景区是国家AAAAA级旅游景区，与黄鹤楼、岳阳楼、滕
                王阁并称为“中国四大名楼”，全国重点文物保护单位，国家重点风景名胜
                区。景区先后荣获“全国五一劳动奖状”
                、“全国旅游行业最佳诚信单位”、“
                全国文明单位”、“中国驰名商标”、“全国巾帼文明岗”等荣誉百余项，景区
                年均接待中外游客200万人次，旅游收入逾亿元，带动相关产业实现综合
                收入达15亿元。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="business_footer">
      <div class="business_footer_flex">
        <div class="footer_flex_leftbox">
          <img
            style="width: 100%; height: 100%"
            src="../assets/newImg/business/yw_footleft.jpg"
            alt=""
          />
        </div>
        <div class="footer_flex_rightimg">
          <img
            style="width: 100%; height: 100%"
            src="../assets/newImg/business/yw_footright.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      active: 0,
      active2: 0,
      active3: 0,
      active4: 0,
      active5: 0,
      active6: 0,
      MFArr: [
        { img: require("../assets/newImg/business/mifeng/m1.jpg") },
        { img: require("../assets/newImg/business/mifeng/m2.jpg") },
        { img: require("../assets/newImg/business/mifeng/m3.jpg") },
        { img: require("../assets/newImg/business/mifeng/m4.jpg") },
        { img: require("../assets/newImg/business/mifeng/m5.jpg") },
      ],
      NHArr: [
        { img: require("../assets/newImg/business/nanhai/n1.jpg") },
        { img: require("../assets/newImg/business/nanhai/n2.jpg") },
        { img: require("../assets/newImg/business/nanhai/n3.jpg") },
        { img: require("../assets/newImg/business/nanhai/n4.jpg") },
      ],
      PTSArr: [
        { img: require("../assets/newImg/business/putuoshan/p1.jpg") },
        { img: require("../assets/newImg/business/putuoshan/p2.jpg") },
        { img: require("../assets/newImg/business/putuoshan/p3.jpg") },
        { img: require("../assets/newImg/business/putuoshan/p4.jpg") },
        { img: require("../assets/newImg/business/putuoshan/p5.jpg") },
      ],
      DHArr: [
        { img: require("../assets/newImg/business/dunhuang/d1.jpg") },
        { img: require("../assets/newImg/business/dunhuang/d2.jpg") },
      ],
      SSArr: [
        { img: require("../assets/newImg/business/shenshou/s1.jpg") },
        { img: require("../assets/newImg/business/shenshou/s2.jpg") },
        { img: require("../assets/newImg/business/shenshou/s3.jpg") },
        { img: require("../assets/newImg/business/shenshou/s4.jpg") },
      ],
      BXArr: [
        { img: require("../assets/newImg/business/baxian/b1.jpg") },
        { img: require("../assets/newImg/business/baxian/b2.jpg") },
        { img: require("../assets/newImg/business/baxian/b3.jpg") },
        { img: require("../assets/newImg/business/baxian/b4.jpg") },
      ],
    };
  },
  created() {},
  mounted() {
    // 切换页面时滚动条自动滚动到顶部
    window.scrollTo(0, 0);
  },
  computed: {},
  methods: {
    // 1
    clickBtn(e) {
      this.$refs.carousel.setActiveItem(e);
      this.active = e;
    },
    changeItem(e) {
      console.log(e, "e");
      this.active = e;
    },
    // 2
    clickBtn2(e) {
      this.$refs.carousel2.setActiveItem(e);
      this.active2 = e;
    },
    changeItem2(e) {
      console.log(e, "e");
      this.active2 = e;
    },
    // 3
    clickBtn3(e) {
      this.$refs.carousel3.setActiveItem(e);
      this.active3 = e;
    },
    changeItem3(e) {
      console.log(e, "e");
      this.active3 = e;
    },
    // 4
    clickBtn4(e) {
      this.$refs.carousel4.setActiveItem(e);
      this.active4 = e;
    },
    changeItem4(e) {
      console.log(e, "e");
      this.active4 = e;
    },
    // 5
    clickBtn5(e) {
      this.$refs.carousel5.setActiveItem(e);
      this.active5 = e;
    },
    changeItem5(e) {
      console.log(e, "e");
      this.active5 = e;
    },
    // 6
    clickBtn6(e) {
      this.$refs.carousel6.setActiveItem(e);
      this.active6 = e;
    },
    changeItem6(e) {
      console.log(e, "e");
      this.active6 = e;
    },
  },
};
</script>
<style lang="less" scoped>
#business {
  height: 100%;
  width: 100%;
  min-width: 1300px;
  .business_banner {
    position: relative;
    width: 100%;
    height: 300px;
    min-width: 1300px;
    overflow: hidden;
    background-image: url("../assets/newImg/business/yw_banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    margin-top: 70px;

    .business_banner_p {
      color: #fff;
      font-size: 58.9px;
      text-align: center;
      line-height: 300px;
      align-items: center;
      font-family: "DIN-Black";
    }
  }
  .business_contbox {
    position: relative;
    width: 100%;
    // height: 1300px;
    min-width: 1300px;
    overflow: hidden;
    // background-image: url("../assets/img/appleTV.png");
    background: #000;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    .business_contbox_bj {
      width: 428.5px;
      height: 402.8px;
      background-image: url("../assets/newImg/business/yw_contbj1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0;
    }
    .business_contbox_bj_right {
      width: 677px;
      height: 155.7px;
      background-image: url("../assets/newImg/business/yw_contbj2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      right: 0px;
      bottom: 186.1px;
    }
    .business_contbox_bj1 {
      width: 677px;
      height: 155.7px;
      background-image: url("../assets/newImg/business/yw_contbj2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      bottom: 186.1px;
    }
    .business_contbox_flex {
      width: 1300px;
      margin: auto;
      // display: flex;
      .business_contbox_flex_p {
        color: rgb(221, 221, 221);
        font-size: 44px;
        font-family: "CFZJ";
        margin-left: 102.2px;
      }
      .business_contbox_flex_span {
        color: rgb(221, 221, 221);
        font-size: 19px;
        font-family: "CFZJ";
        margin-top: 93.4px;
      }
      .business_contbox_flex_img {
        width: 677px;
        height: 21.6px;
        background-image: url("../assets/newImg/business/yw_topxian.png");
        background-size: 100%;
        background-repeat: no-repeat;
        margin-top: 1.9rem;
        margin-left: 13.5px;
      }
    }
    .business_contbox_flex_p1 {
      color: rgb(221, 221, 221);
      font-size: 58.2px;
      font-family: "DIN-Black";
      margin-left: 102.2px;
    }
    .business_contbox_contflex {
      display: flex;
      margin-top: 37.2px;
      .business_contbox_contflex_left {
        z-index: 9;
        margin: 0 67.7px;
        display: flex;
        .business_contbox_contflex_leftbox1 {
          width: 365.6px;
          text-align: center;
          .business_contbox_contflex_left_img {
            width: 250.4px;
            height: 250.4px;
            background-image: url("../assets/newImg/business/yw_mifeng.png");
            background-size: 100%;
            background-repeat: no-repeat;
            margin-left: 44px;
          }
          .business_contbox_contflex_left_img2 {
            width: 206.5px;
            height: 206.5px;
            background-image: url("../assets/newImg/business/nanhai/n_right.jpg");
            background-size: 100%;
            background-repeat: no-repeat;
            margin: auto;
          }
          .business_contbox_contflex_left_img3 {
            width: 206.5px;
            height: 206.5px;
            background-image: url("../assets/newImg/business/putuoshan/p_right.jpg");
            background-size: 100%;
            background-repeat: no-repeat;
            margin: auto;
          }
          .business_contbox_contflex_left_img4 {
            width: 206.5px;
            height: 206.5px;
            background-image: url("../assets/newImg/business/dunhuang/d_right.jpg");
            background-size: 100%;
            background-repeat: no-repeat;
            margin: auto;
          }
          .business_contbox_contflex_left_img5 {
            height: 250.5px;
            background-image: url("../assets/newImg/business/yw_guli.png");
            background-size: 100%;
            background-repeat: no-repeat;
            margin: auto;
          }
          .business_contbox_contflex_left_img6 {
            width: 206.5px;
            height: 206.5px;
            background-image: url("../assets/newImg/business/baxian/b_right.jpg");
            background-size: 100%;
            background-repeat: no-repeat;
            margin: auto;
          }
          .business_contbox_contflex_left_p1 {
            color: #fff;
            font-size: 17px;
            font-family: "CFZJ";
          }
          .business_contbox_contflex_left_p2 {
            color: #fff;
            font-size: 11.5px;
            font-family: "DIN-Black";
            margin-top: 20.3px;
            line-height: 18px;
            width: 365.6px;
          }
        }
        .box2_img {
          width: 47.4px;
          height: 339.9px;
          background-image: url("../assets/newImg/business/yw_celianwan.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-left: 40.6px;
        }
        .box21_img {
          width: 47.4px;
          height: 339.9px;
          background-image: url("../assets/newImg/business/yw_celianwan.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 40.6px;
          margin-left: 6.8px;
        }
        .box3_bj {
          width: 622.8px;
          height: 359.5px;
          background-image: url("../assets/newImg/business/ye_lb_bj.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-left: 6.7px;
          padding: 17px 0 0 28.4px;
          .box3_banner {
            width: 597.8px;
            height: 334.4px;
            // background: aqua;
          }
        }
        .box31_bj {
          width: 622.8px;
          height: 359.5px;
          background-image: url("../assets/newImg/business/ye_lb_bj.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          padding: 17px 0 0 28.4px;
          .box3_banner {
            width: 597.8px;
            height: 334.4px;
            // background: aqua;
          }
        }
        .box3_banner_btnbox {
          width: 270.8px;
          display: flex;
          justify-content: space-between;
          margin: auto;
          margin-bottom: 211.9px;
          margin-top: 20.3px;
          .box3_banner_btn {
            width: 26.4px;
            height: 26.4px;
            background-image: url("../assets/newImg/business/btn.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          .box3_banner_btnxz {
            width: 26.4px;
            height: 26.4px;
            background-image: url("../assets/newImg/business/xzbtn.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
  .business_contbox_footimg {
    width: 100%;
    height: 23.7px;
    background: #000;
    .business_contbox_footimg1 {
      width: 100%;
      height: 23.7px;
      background-image: url("../assets/newImg/business/yw_footxian.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .business_footer {
    width: 100%;
    .business_footer_flex {
      display: flex;
      .footer_flex_leftbox {
        width: 47%;
      }
      .footer_flex_rightimg {
        width: calc(100% - 47%);
      }
    }
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 101.5px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}
</style>
