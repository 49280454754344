const baseSize = 100
// 设置 rem 函数
function setRem () {
  let clientWidth = document.documentElement.clientWidth
  let scale = clientWidth>1300?clientWidth / 1920:0.677
  document.documentElement.style.fontSize = baseSize * scale + 'px'
  
  
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}
